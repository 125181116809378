.card {
    width: 300px;
}

.cart {
    margin-top: 40px;
}

.box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.card .card-image .card-title {
    color: black;
    font-weight: 400;
}

.collection .collection-item img {
    width: 150px;
    margin-right: 25px;
}

.collection .collection-item {
    display: flex;
}